<template>
  <div class="ReportBox">
    <TitleHeader msg="订单药检报告"></TitleHeader>
     <p class="progressBar"></p>
    
     <el-row class="header">
       <el-col :span="2">订单状态：</el-col>
        <el-col :span="4">
          <el-select v-model="statusType" placeholder="请选择">
            <el-option
              v-for="item in content"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
         <el-col :span="1"></el-col>
        <el-col :span="6">
              <el-date-picker
            v-model="TimeValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
           format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd"
            >
          </el-date-picker>
           </el-col>
     </el-row>
     <el-row class="header">
      <el-col :span="2">输入搜索：</el-col>
      <el-col :span="8"
        ><el-input
          placeholder="订单ID"
          v-model="OrderId"
          clearable
        ></el-input
      ></el-col>
       <el-col :span="6"
        ><el-button @click="search" class="search">查询</el-button></el-col
      >
    </el-row>
    <el-table
      v-loading="loading"
      :data="tableData"
      class="wraper"
      style="width: 100%;"
    >
      <el-table-column
        class="text-center"
        prop="id"
        label="订单ID"
        width="180"
      >
      </el-table-column>
      <el-table-column class="text-center"  label="交易状态">
       <template slot-scope="scope">
          <p>{{
             scope.row.status === 1
                    ? "待支付"
                    : scope.row.status === 2
                    ? "待发货"
                    : scope.row.status === 3
                    ? "待收货"
                    : scope.row.status === 4
                    ? "已完成"
                    : "已取消"
            
            }}</p>
        </template>
      </el-table-column>
      <el-table-column class="text-center" prop="createTime" label="下单时间" width="180">
      </el-table-column>
      <el-table-column class="text-center" prop="payableAmount" label="交易金额">
      </el-table-column>
      <el-table-column class="text-center" label="订单药检报告">
        <template slot-scope="scope">
          <el-button  type="text" size="small" @click.native="DownInfoFun(scope.row.id)">查看下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :hide-on-single-page="false"
      prev-text="上一页"
      next-text="下一页"
      :page-size="10"
      :current-page="currentPage"
      :total="total"
       @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { OrderList,GetOrderReport } from "api/order.js";

const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "ReportBox",
  data() {
    return {
      TimeValue:'',
      OrderId:'',//传给后台的搜索值id
      pagesize: 10,
      currentPage: 1,//当前页
       startTime: "",
      endTime: "",
      total: 0,
      size: 10, //每页数量
      loading: false,
      //导航栏信息
      statusType:3,
        content: [
          // {
          //   value: 0,
          //   label: "全部",
          // },
          // {
          //   value: 1,
          //   label: "待付款",
          // },
          // {
          //   value: 2,
          //   label: "待发货",
          // },
          {
            value: 3,
            label: "待收货",
          },
          {
            value: 4,
            label: "已完成",
          },
          // {
          //   value: 5,
          //   label: "已取消",
          // },
        ],
      tableData: [],
    };
  },
  methods: {
    // 下载订单药检资料
    DownInfoFun(OrderId) {
      GetOrderReport(OrderId).then((res) => {
          if (res.data.type == "application/json") {
            console.log(res)
            this.$message({
              message: '暂无药检报告',
              type: "warning",
            });
          } else {
           // 下载pdf
            this.pdfUrl = window.URL.createObjectURL(new Blob([res.data], { type: `application/pdf` }));
            const fname = "订单号"+OrderId+'药检报告'; // 下载文件的名字
            const link = document.createElement('a');
            link.href = this.pdfUrl;
            link.setAttribute('download', fname);
            document.body.appendChild(link);
            link.click();
          }
      });
    },
     handleCurrentChange(val) {
      this.currentPage = val;
       this.GetOrderPageFun();
    },
    search() {
      if(this.TimeValue){
          this.startTime=this.TimeValue[0];
           this.endTime=this.TimeValue[1]
      }
      //搜索条件
     this.currentPage = 1;
      this.loading = true;
      this.GetOrderPageFun();
    },
    GetOrderPageFun(){
      let datas = "";
     // 1：待支付，2待发货，3待收货，4已完成，5已取消
      if (this.statusType > 0) {
        datas = {
          id:this.OrderId,
          status: this.statusType,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime:  this.endTime,
        };
      } else {
        datas = {
           id:this.OrderId,
          size: this.pagesize,
          current: this.currentPage,
          startTime: this.startTime,
          endTime: this.endTime,
        };
      }
       //获取订单列表
      OrderList(datas).then(data=>{
        if (data.data.code === 400) {
          this.$message.error(data.data.msg);
        }
        this.tableData=data.data.data.records
         // 初始获取所有数据数量
        this.total = data.data.data.total;
        // 关闭loading
         this.loading = false;
      })
    },
  },
  created() {
    this.GetOrderPageFun();
  },
  components: {
    TitleHeader,
  },
};
</script>
<style>

/* 表格样式 */
.ReportBox .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff !important;
}
.ReportBox .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.ReportBox .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: none;
}
/* th样式 */
.ReportBox .el-table th {
  background-color: #eee;
  text-align: center;
}
/* td样式 */
.ReportBox .el-table thead .cell {
  text-align: center;
    font-size: 13px;
    color:#666;
}
.ReportBox .el-table tbody .cell {
  text-align: center;
    font-size:12px;
    color:#333;
}
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #ed3129; 
        color: #000;
    }
/* 上一页，下一页样式 */
.el-pagination button, .el-pagination span:not([class*=suffix]){
    background: #fff;
    color: #666;
     padding: 4px;
     box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li{
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover{
    color:#ed3129; 
    font-size: 14px;
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
  .progressBar {
    height: 20px;
  }
.header {
  padding-left:10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  .search {
    margin-left: 20px;
  }
}

.wraper {
  margin-top: 30px;
  .el-table thead {
    background: red;
  }
}
.el-pagination {
  padding-top: 40px;
  text-align: center;
}
</style>
